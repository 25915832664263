import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import Danger from "src/components/alerts/Danger/Danger";
import API from "../../api/api";
import Success from "src/components/alerts/Success/Success";

const initialState = {
  loading: false,
  allUsers: [],
  userDetails: {},
  userStatistics: {},
  page: 1,
  pages: 1,
  pageSize: 10,
  error: "",
  allUsersList: [],
};

const defaultPath = "/users";

export const fetchAllUsers = createAsyncThunk(
  "fetch_all_users",
  async ({
    page = 1,
    pageSize = 10,
    search = "",
    startDate = "",
    endDate = "",
    status = "",
  }) => {
    try {
      const url = `${defaultPath}/all-users?page=${page}&pageSize=${pageSize}&search=${search}&startDate=${startDate}&endDate=${endDate}&status=${status}`;
      const { data } = await API.get(url);
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const fetchAllUsersList = createAsyncThunk(
  "fetch_user_list",
  async () => {
    try {
      const { data } = await API.get(`${defaultPath}/all-user-list`);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const updateUserActiveStatus = createAsyncThunk(
  "updateUserStatus",
  async (userId) => {
    try {
      const { data } = await API.patch(
        `${defaultPath}/update-status/${userId}`
      );
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const fetchUserStatistics = createAsyncThunk(
  "fetchUserStatistics",
  async (userId) => {
    try {
      const { data } = await API.get(
        `${defaultPath}/user-statistics/${userId}`
      );
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const writeUserNotes = createAsyncThunk(
  "writeUserNotes",
  async ({ userId, notes }) => {
    try {
      const { data } = await API.put(`${defaultPath}/write-note/${userId}`, {
        notes,
      });
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  extraReducers: (builder) => {
    // fetch all user
    builder.addCase(fetchAllUsers.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchAllUsers.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allUsers = action.payload.allUsers;
        state.page = action.payload.page;
        state.pages = action.payload.pages;
        state.pageSize = action.payload.pageSize;
      }
    });

    // fetch all user list
    builder.addCase(fetchAllUsersList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchAllUsersList.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allUsersList = action.payload.allUsers;
      }
    });

    // update  user status
    builder.addCase(updateUserActiveStatus.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateUserActiveStatus.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allUsers.map((item) => {
          if (item._id === action.payload.user._id) {
            item.status = action.payload.user.status;
            return item;
          }
          return item;
        });
        toast.custom(<Success message={`user status updated successfully`} />);
      }
    });
    // user statistics
    builder.addCase(fetchUserStatistics.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchUserStatistics.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.userStatistics = action.payload.user;
      }
    });
    // update  user notes
    builder.addCase(writeUserNotes.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(writeUserNotes.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allUsers.map((item) => {
          if (item._id === action.payload.user._id) {
            item.notes = action.payload.user.notes;
            return item;
          }
          return item;
        });
        toast.custom(<Success message={`user status updated successfully`} />);
      }
    });
  },
});

export const userReducer = userSlice.reducer;
